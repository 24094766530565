:root {  
    --font-family: "Poppins", system-ui;
  }

  @font-face {
    font-family: 'Aloja';
    src: local('Aloja'), url(../fonts/Aloja-Light.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  .logo-text{
    font-family: 'Aloja';
    font-size: '200px';
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    max-width: 1920px;
    min-height: 100vh;
    display: grid;
    place-items: center;
    padding: 2rem;
    font-family: var(--font-family);
    color: white;
  }
  
  strong {
    font-weight: 600;
  }
  
  .overlay {
    width: 100%;
    max-width: 1140px;
    max-height: 640px;
    padding: 8rem 6rem;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
    justify-content: center;
  }
  
  .overlay__inner {
    max-width: 36rem;
    text-align: center;
  }
  
  .overlay__title {
    font-size: 1.875rem;
    line-height: 2.75rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    /* margin-bottom: 2rem; */
  }
  
  .text-gradient {
    background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .text-glow {
    text-shadow: 0 0 25px rgb(192 219 255 / 75%), 0 0 10px rgb(65 120 255 / 24%);
  }
  
  .overlay__description {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 3rem;
  }
  
  .overlay__btns {
    width: 100%;
    max-width: 30rem;
    display: flex;
  }
  
  .overlay__btn {
    width: 50%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--light-color);
    background: var(--dark-color);
    border: none;
    border-radius: 0.5rem;
    transition: transform 150ms ease;
    outline-color: hsl(var(--hue), 95%, 50%);
  }
  
  .overlay__btn:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .overlay__btn--transparent {
    background: transparent;
    color: var(--dark-color);
    border: 2px solid var(--dark-color);
    border-width: 2px;
    margin-right: 0.75rem;
  }
  
  .overlay__btn-emoji {
    margin-left: 0.375rem;
  }
  
  a {
    text-decoration: none;
    color: var(--dark-color);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Not too many browser support this yet but it's good to add! */
  @media (prefers-contrast: high) {
    .orb-canvas {
      display: none;
    }
  }
  
  @media only screen and (max-width: 1140px) {
    .overlay {
      padding: 8rem 4rem;
    }
  }
  
  @media only screen and (max-width: 840px) {
    body {
      padding: 1.5rem;
    }
  
    .overlay {
      padding: 4rem;
      height: auto;
    }
  
    .overlay__title {
      font-size: 1.25rem;
      line-height: 2rem;
      /* margin-bottom: 1.5rem; */
    }
  
    .overlay__description {
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin-bottom: 2.5rem;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .overlay {
      padding: 1.5rem;
    }
  
    .overlay__btns {
      flex-wrap: wrap;
    }
  
    .overlay__btn {
      width: 100%;
      font-size: 0.75rem;
      margin-right: 0;
    }
  
    .overlay__btn:first-child {
      margin-bottom: 1rem;
    }
  }

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: black;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: rotate 2.5s linear infinite;
  box-shadow: 0px 5px 5px 3px #fece07;
  /* content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #5846f9;
  border-top-color: #e7e4fe;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite; */
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}


body {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
